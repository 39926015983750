import React, { Component } from 'react';

// import AnchorLink from 'react-anchor-link-smooth-scroll';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './casestudyfujitsu.scss';

import imgQuote from "../images/quotation_mark.webp";

import SimpleModal from '../components/utilities/SimpleModal';

class OneImageFull extends Component {
    render() {
        const divStyles = {
            backgroundImage: 'url('+this.props.imageOne+')',
            backgroundSize: 'cover',
            height: this.props.height,
            backgroundPosition: '50% 50%'
        };
        return (
            <div className="container-fluid one-image-wrapper p-0">
                <div className="row align-items-center no-gutters">
                    <div className="col-12" style={divStyles}>

                    </div>
                </div>
            </div>
        );
    }
}

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className="case-study-three-wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="header-container" style={{backgroundImage: `url(${pageContext.acf.header_image.csTwoHedImaLocalFile.childImageSharp.fluid.src})`}}>
                        <img src={imgQuote} alt=""/>
                        <h2>{pageContext.acf.header_text}</h2>
                    </div>
                </div>
            </div>
            <div className="container detail-wrapper">
                <div className="row">
                    <div className="col-md-8">
                        <img src={pageContext.acf.client_logo.source_url} className="client-logo" width="174" height="75" alt={pageContext.yoast.opengraph_title} />
                    </div>
                    <div className="col-md-4">
                        <a href="https://www.meritmile.com/uploads/2022/11/fujitsu-and-microsoft-case-study.pdf" className="btn downloadBtn" target="_blank" rel="nofollow noopener noreferrer" style={{ marginTop: '4em'}}>Download the PDF here</a>
                    </div>
                    <div className="col-12">
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
            <div class="container detail-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="first-paragraph">
                            <p>As the business world reacted to COVID-19 in early 2020, remote working became the de facto standard. As workforces shifted to remote work, the IT resources they depend on also went remote. Business and IT leaders needed to ensure that the same strong security policies and practices happening behind the firewall went remote as well.</p>
                        </div>
                    </div>
                    <div class="col-12"></div>
                </div>
            </div>
            <div class="container single-image-text-wrapper challenge detail-wrapper">
                <div class="row">
                    <div class="col-md-6 order-last">           
                        <img src="https://www.meritmile.com/uploads/2020/08/fujitsu-graphic-top@2x.png" class="img-fluid" width="493" height="535" alt=""              />
                    </div>
                    <div class="col-md-6 text">
                        <div class="text">
                            <h3>Challenge</h3>
                            <p>Fujitsu and Microsoft are global partners providing secure workplace solutions to enterprise customers. As their customers raced to support remote work models, security was top of mind. IT leaders were clamoring for proven advice and technical know-how they could implement immediately. Time was of the essence. Fujitsu and Microsoft had the field proven expertise and insight businesses were asking for, but they needed a way reach their market.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container single-image-text-wrapper solutions detail-wrapper">
                <div class="row">
                    <div class="col-md-6 text">
                        <div class="text">
                            <h3>Solutions</h3>
                            <p>With the pressure to implement secure remote working solutions as fast as possible, customers needed fact, not theory. Merit Mile used a combination of real-world experience plus technical insights from Fujitsu and Microsoft security solutions experts as the most credible way to position Fujitsu as an industry thought leader on secure remote work. The integrated marketing campaign became founded on a <em>for experts, from experts</em> point of view, where IT customers could cut through the noise and get right to the relevant information they asked for.</p>
                        </div>
                    </div>
                    <div class="col-md-6 order-md-first">
                        <img src="https://www.meritmile.com/uploads/2020/08/fujitsu-graphic-solutions@2x.png" class="img-fluid" width="518" height="360" alt="" />
                    </div>
                </div>
            </div>
            <div class="container results detail-wrapper">
                <div class="row">
                    <div class="col-12 text">
                        <div class="text">
                            <h3>Results</h3>
                            <p>Within weeks, Merit Mile developed an integrated content marketing and demand generation campaign. First, Fujitsu and Microsoft security experts were interviewed by Merit Mile, collecting the source of technical information that was needed. Next, the findings were transposed into marketing content including an eBook, an interview brief with a Fujitsu security leader, blogs, and social content. To drive demand, a series of social media <em>snack size</em> videos and an email nurture series were launched.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container single-image-text-wrapper deliverables detail-wrapper">
                <div class="row">
                    <div class="col-md-8 order-last d-flex justify-content-center align-items-end">
                        <img src="https://www.meritmile.com/uploads/2020/08/fujitsu-graphic-deliverables@2x.png" class="img-fluid" width="0" height="0" alt="" />
                    </div>
                    <div class="col-md-4 text">
                        <div class="text">
                            <h3>Deliverables</h3>
                            <ul>
                                <li>eBook</li>
                                <li>Leadership interview</li>
                                <li>Social media advertising videos</li>
                                <li>Email campaigns</li>
                                <li>Blogs</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container quote-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="text">
                        <p>For more insights and to connect with Merit Mile directly please <a href="mailto:microsoft@meritmile.com?subject=Fujitsu 2020 Case Study Inquiry">email us here</a>.</p>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </Layout>
)